import React, { useCallback } from 'react';
import { DocumentState, DocumentType, InvoiceType } from '../../../Library/Types/DocumentTypes';
import ShowHideContainer from '../../ShowHideContainer';
import Input, { InputType } from '../../Forms/Input';
import Button from '../../Forms/Button';
import Container from '../../Container/Container';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useDispatchDocumentUpdate } from '../../../Redux/Actions/DocumentActions';
import TextList from '../TextList';
import BankInformationForm from './BankInformationForm';
import InvoiceInformationForm from './InvoiceInformationForm';
import validateDocumentInformation from '../../../Library/Validators/DocumentInformation';
import { useCopyClipboard } from '../../../Library/Hooks/GlobalHooks';
import { useAppSelector } from '../../../Library/Hooks/ReduxHooks';
import { BankAccount, Client } from '../../../Library/Types/ClientTypes';
import ConfirmModal from '../../Modals/ConfirmModal';
import TypeSelect from './TypeSelect';
import {
  handleSetTextsForBankDocuments,
  onPressTextHandler,
  useCheckBankDates,
  useDocumentCheckExisting,
} from './HelperFunctions';
import { useDispatchClientUpdate } from '../../../Redux/Actions/ClientActions';
import { useDocumentAppendInvoiceData } from '../../../Library/Hooks/DocumentHooks';
import { NavbarContext } from '../../Layout/AuthedLayout';
import ProcessingAi from '../ProcessingAi';
import { DocumentUpdateActions, DocumentUpdateState } from '../DocumentUpdateReducer';

interface Props {
  document: DocumentType;
  texts?: string[];
  disabled: boolean;
  goBackAfterSave: boolean;
  dispatch: React.Dispatch<DocumentUpdateActions>;
  state: DocumentUpdateState;
}

/**
 * EditInformationForm()
 * @param props
 * @constructor
 */
export default function EditInformationForm(props: Props) {
  const { document, texts, disabled, goBackAfterSave, dispatch, state } = props;
  const { clients, user } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [showErrors, setShowErrors] = React.useState<boolean>(false);
  const [askAddBankAccount, setAskAddBankAccount] = React.useState<boolean>(false);
  const [showAskSave, setShowAskSave] = React.useState<boolean>(false);
  const { clipboardCallout, copyToClipBoard } = useCopyClipboard();
  const [client, setClient] = React.useState<Client>();
  const [loadingInvoiceData, setLoadingInvoiceData] = React.useState<boolean>(false);
  const { checkBankDate, alertModal: bankAlertModal } = useCheckBankDates();
  const { alertModal: existingModal, checkDocumentExisting } = useDocumentCheckExisting();
  const [autoCompleteExecuted, setAutoCompleteExecuted] = React.useState<boolean>(false);
  const navContext = React.useContext(NavbarContext);

  const dispatchUpdate = useDispatchDocumentUpdate();
  const dispatchUpdateClient = useDispatchClientUpdate();
  const appendInvoiceInformation = useDocumentAppendInvoiceData();

  const handleNavigateBack = useCallback(() => {
    dispatch({ type: 'resetHasChanges' });
    if (goBackAfterSave) {
      navigate(-1);
    }
  }, [dispatch, goBackAfterSave, navigate]);

  const handleAddBankAccount = useCallback(() => {
    if (client) {
      const account: BankAccount = { bankName: state.data.bankName, bankIban: state.data.bankIban.replaceAll(' ', '') };
      dispatchUpdateClient({
        ...client,
        settings: {
          ...client.settings,
          bankAccounts:
            client.settings && client.settings.bankAccounts ? [...client.settings.bankAccounts, account] : [account],
        },
      }).finally(() => handleNavigateBack());
    }
  }, [client, dispatchUpdateClient, handleNavigateBack, state.data.bankIban, state.data.bankName]);

  const handleAskAddBankAccount = useCallback(() => {
    if (state.data.type === InvoiceType.bank && !user.isCustomerUser && client) {
      if (client.settings && client.settings.bankAccounts && client.settings.bankAccounts.length > 0) {
        const found = client.settings.bankAccounts.find((item) => item.bankIban === state.data.bankIban);
        if (!found) {
          setAskAddBankAccount(true);
        } else {
          handleNavigateBack();
        }
      } else {
        setAskAddBankAccount(true);
      }
    } else {
      handleNavigateBack();
    }
  }, [state.data.type, state.data.bankIban, user.isCustomerUser, client, handleNavigateBack]);

  const handleSave = React.useCallback(async () => {
    setShowErrors(false);
    const { creditor, invoiceNumber, bankStartDate, type } = state.data;

    const isValid = validateDocumentInformation(state);
    const isBankValid = client
      ? checkBankDate(document.documentId, state.data.type, state.data.bankIban, client, bankStartDate)
      : true;
    const documentExisting = client
      ? await checkDocumentExisting(document.documentId, type, client.clientId, creditor, invoiceNumber)
      : false;

    if (client && isValid && isBankValid && !documentExisting) {
      if (document) {
        dispatchUpdate({ ...document, ...state.data, state: DocumentState.open }).finally(() =>
          handleAskAddBankAccount(),
        );
      }
    } else {
      setShowErrors(true);
    }
  }, [checkBankDate, checkDocumentExisting, client, dispatchUpdate, document, handleAskAddBankAccount, state]);

  React.useEffect(() => {
    if (
      state.data.type !== InvoiceType.bank &&
      client &&
      !autoCompleteExecuted &&
      !state.data.creditor &&
      document.state === DocumentState.uploaded
    ) {
      setAutoCompleteExecuted(true);
      setLoadingInvoiceData(true);
      navContext.setShowLoading(true);

      appendInvoiceInformation({ ...document, ...state.data }, client).then((mappedDocument) => {
        dispatch({ type: 'updateInvoiceData', payload: mappedDocument });
        navContext.setShowLoading(false);
        setLoadingInvoiceData(false);
      });
    }
  }, [appendInvoiceInformation, autoCompleteExecuted, client, dispatch, document, navContext, state.data]);

  React.useEffect(() => {
    if (texts && texts.length > 0 && client) {
      handleSetTextsForBankDocuments(texts, client, state, dispatch);
    }
  }, [appendInvoiceInformation, autoCompleteExecuted, client, dispatch, document, navContext, state, texts]);

  React.useEffect(() => {
    if (clients && document && document.clientId) {
      const found = clients.find((item) => item.clientId === document.clientId);
      setClient(found);
    }
  }, [clients, document]);

  React.useEffect(() => {
    if (!document) {
      dispatch({ type: 'clear' });
    } else {
      dispatch({ type: 'init', payload: document });
    }
  }, [dispatch, document, state.initialized]);

  const handleOnPressText = (text: string) => {
    if (!disabled) {
      onPressTextHandler(text, state, dispatch, copyToClipBoard);
    } else {
      copyToClipBoard(text);
    }
  };

  if (client) {
    return (
      <>
        <ProcessingAi visible={loadingInvoiceData} isImage={document.fileType.indexOf('image') > -1} />
        <Container>
          <div className={'flex-col'}>
            <TypeSelect
              selected={state.data.type}
              disabled={disabled}
              client={client}
              onChange={(payload) => dispatch({ type: 'type', payload })}
              hasError={showErrors && state.data.type === InvoiceType.unknown}
            />

            <BankInformationForm
              dispatch={dispatch}
              state={state}
              disabled={disabled}
              showErrors={showErrors}
              client={client}
            />
            <InvoiceInformationForm
              dispatch={dispatch}
              state={state}
              disabled={disabled}
              showErrors={showErrors}
              loadingAi={loadingInvoiceData}
            />

            <Input
              onChange={(payload) => dispatch({ type: 'additionalInformation', payload })}
              value={state.data.additionalInformation}
              label={t('additionalInformation')}
              type={InputType.textarea}
              rows={3}
              maxLength={200}
            />
            <Input
              onChange={(payload) => dispatch({ type: 'originalName', payload })}
              label={t('fileName')}
              value={state.fileName}
              hasError={showErrors && !state.data.originalName}
              disabled={disabled}
              showCopy
              rightText={`.${state.fileExtension}`}
            />
          </div>

          <div style={{ textAlign: 'right' }} className={'mt-6'}>
            <Button onPress={state.hasChanges ? () => setShowAskSave(true) : () => navigate(-1)} colorStyle={'cancel'}>
              <div className={'px-2 py-1 text-lg'}>{t('cancel')}</div>
            </Button>
            <ShowHideContainer visible={!disabled}>
              <Button onPress={handleSave} colorStyle={'success'} disabled={!state.hasChanges}>
                <div className={'px-2 py-1 text-lg'}>
                  <i className={'far fa-save mr-3'} />
                  {t('save')}
                </div>
              </Button>
            </ShowHideContainer>
          </div>
        </Container>

        <TextList
          texts={texts ? texts : []}
          onPress={handleOnPressText}
          description={t('modules.documents.pdfTextDescription')}
        />

        <ConfirmModal
          headline={t('alert.askAddBankAccount.headline')}
          text={t('alert.askAddBankAccount.text')}
          visible={askAddBankAccount}
          onClose={() => {
            setAskAddBankAccount(false);
            handleNavigateBack();
          }}
          onConfirm={handleAddBankAccount}
          type={'primary'}
          confirmText={t('save')}
          cancelText={t('cancel')}
        />

        {bankAlertModal}
        {existingModal}
        {clipboardCallout}

        <ConfirmModal
          headline={t('alert.askSaveChanges.headline')}
          text={t('alert.askSaveChanges.text')}
          visible={showAskSave}
          onClose={() => setShowAskSave(false)}
          onConfirm={() => navigate(-1)}
          type={'error'}
          confirmText={t('destroyChanges')}
        />
      </>
    );
  }
  return null;
}
